import { useEffect } from "react";
import { t } from "ttag";
import { useDispatch } from "metabase/lib/redux";
import { AuthButton } from "metabase/auth/components/AuthButton";
import MetabaseSettings from "metabase/lib/settings";
import { logout } from "../../actions";
import { AuthLayout } from "../AuthLayout";

export const Logout = (): JSX.Element | null => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <AuthLayout>
      <AuthButton
        icon="okta"
        isCard={true}
        onClick={() => {
          window.location.href = MetabaseSettings.get("site-url");
        }}
      >
        {t`Sign in with Okta`}
      </AuthButton>
    </AuthLayout>
  );
};
