var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AH,BH,DH,EH,Jfa,Kfa,Lfa,FH,CH;$CLJS.yH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.zH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
AH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.wC(b,$CLJS.XC)?$CLJS.jH:$CLJS.wC(b,$CLJS.WD)?$CLJS.kE:$CLJS.wC(b,$CLJS.wD)?$CLJS.lE:null;return $CLJS.n(b)?$CLJS.wF(b,a):!0};
BH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.yH(function(d){return $CLJS.wC($CLJS.UE(d),$CLJS.hD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Mk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.UE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(AH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
DH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.yt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Cj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(BH(b))].join("")}],null),$CLJS.Te(BH)],null)],null)};
EH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.wt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null)],null)};
Jfa=function(a){return $CLJS.Qd($CLJS.zF,$CLJS.hf.g(function(b){var c=$CLJS.UE(b),d=$CLJS.wC(c,$CLJS.fF);b=d?$CLJS.wF($CLJS.tG,b):d;return $CLJS.n(b)?$CLJS.Oj:c},a))};Kfa=function(a){a=$CLJS.zH(function(b){return!$CLJS.wC(b,$CLJS.hD)},$CLJS.hf.g($CLJS.UE,a));return $CLJS.wC(a,$CLJS.fF)?$CLJS.Vj:a};
Lfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.wC($CLJS.UE(c),$CLJS.hD)},b))?Kfa(b):$CLJS.E.g(a,$CLJS.pt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.wC($CLJS.UE(c),$CLJS.XC)},b)||$CLJS.Qe(function(c){return $CLJS.wC($CLJS.UE(c),$CLJS.wD)},b))?$CLJS.hD:Jfa(b)};FH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.GH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);CH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$E,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.as,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,CH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.wt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.UE(a);return $CLJS.Qe(function(d){return AH(d,c)},b)}],null)],null));
$CLJS.X(FH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.h(null,1,[$CLJS.Yn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)],null));
var Mfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ks,new $CLJS.h(null,1,[$CLJS.wt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,$CLJS.pt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],
null);$CLJS.PG.g($CLJS.as,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,DH($CLJS.as),EH($CLJS.as)],null));$CLJS.PG.g($CLJS.pt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,DH($CLJS.pt),Mfa,EH($CLJS.pt)],null));$CLJS.GF($CLJS.bs,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,FH],null)]));$CLJS.GF($CLJS.KG,$CLJS.H([$CLJS.pt,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bt,FH],null)]));
for(var HH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.as,$CLJS.pt,$CLJS.bs],null)),IH=null,JH=0,KH=0;;)if(KH<JH){var Nfa=IH.X(null,KH);$CLJS.xF(Nfa,$CLJS.GH);KH+=1}else{var LH=$CLJS.y(HH);if(LH){var MH=LH;if($CLJS.Ad(MH)){var NH=$CLJS.lc(MH),Ofa=$CLJS.mc(MH),Pfa=NH,Qfa=$CLJS.D(NH);HH=Ofa;IH=Pfa;JH=Qfa}else{var Rfa=$CLJS.A(MH);$CLJS.xF(Rfa,$CLJS.GH);HH=$CLJS.B(MH);IH=null;JH=0}KH=0}else break}
$CLJS.TE.m(null,$CLJS.GH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Lfa(a,b)});$CLJS.EF($CLJS.QF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));$CLJS.xF($CLJS.QF,$CLJS.jF);
for(var OH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,$CLJS.HF,$CLJS.cG],null)),PH=null,QH=0,RH=0;;)if(RH<QH){var Sfa=PH.X(null,RH);$CLJS.EF(Sfa,$CLJS.H([$CLJS.pt,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));RH+=1}else{var SH=$CLJS.y(OH);if(SH){var TH=SH;if($CLJS.Ad(TH)){var UH=$CLJS.lc(TH),Tfa=$CLJS.mc(TH),Ufa=UH,Vfa=$CLJS.D(UH);OH=Tfa;PH=Ufa;QH=Vfa}else{var Wfa=$CLJS.A(TH);$CLJS.EF(Wfa,$CLJS.H([$CLJS.pt,$CLJS.TD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));OH=$CLJS.B(TH);PH=null;QH=0}RH=0}else break}
for(var VH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.nG,$CLJS.uG],null)),WH=null,XH=0,YH=0;;)if(YH<XH){var Xfa=WH.X(null,YH);$CLJS.EF(Xfa,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));YH+=1}else{var ZH=$CLJS.y(VH);if(ZH){var $H=ZH;if($CLJS.Ad($H)){var aI=$CLJS.lc($H),Yfa=$CLJS.mc($H),Zfa=aI,$fa=$CLJS.D(aI);VH=Yfa;WH=Zfa;XH=$fa}else{var aga=$CLJS.A($H);$CLJS.EF(aga,$CLJS.H([$CLJS.pt,$CLJS.vj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));VH=$CLJS.B($H);WH=null;XH=0}YH=0}else break}$CLJS.EF($CLJS.NF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null)]));
$CLJS.TE.m(null,$CLJS.NF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.wC($CLJS.UE(b),$CLJS.vj)&&$CLJS.wC($CLJS.UE(a),$CLJS.vj)?$CLJS.vj:$CLJS.TD});