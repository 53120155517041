/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { connect } from "react-redux";
import MetabaseSettings from "metabase/lib/settings";
import LoadingSpinner from "metabase/components/LoadingSpinner";

import { loginOkta } from "metabase/auth/actions";
import OktaAuth from "@okta/okta-auth-js";

class OktaLogin extends Component {
  constructor(props, context) {
    super(props, context);
    if (MetabaseSettings.isOktaEnabled()) {
      this.code = getAuthorizationCodeFromURL();
      this.state = getStateFromURL()
        ? getStateFromURL().split("redirect=")[1]
        : "/";
      console.log(this.state);
      this.original_url = decodeURIComponent(this.state);
      console.log(this.original_url);
      window.history.replaceState({}, document.title, "/" + "auth/okta_login"); //Rewriting URL to remove the code
      this.authClient = new OktaAuth({
        clientId: MetabaseSettings.get("okta-client-id"),
        url: "https://" + MetabaseSettings.get("okta-domain"),
      });
    } else {
      this.state = null;
    }
  }

  render() {
    const { loginOkta } = this.props;
    (authorizationCode => {
      if (authorizationCode) {
        loginOkta(
          { code: authorizationCode, 
            redirectUrl: MetabaseSettings.get("site-url") + this.original_url }
        );
      } else {
        window.location.href =
          MetabaseSettings.get("site-url") + "/auth/okta_login";
      }
    })(this.code);

    return (
      <div className="full-height bg-white flex flex-column flex-full md-layout-centered">
        <div className="wrapper">
          <div className="Login-wrapper Grid--full">
            <div className="Grid-cell flex layout-centered text-brand">
              <LoadingSpinner size={64} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getAuthorizationCodeFromURL() {
  return getUrlParams(window.location.search)["code"];
}

function getStateFromURL() {
  return getUrlParams(window.location.search)["state"];
}

function getUrlParams(search) {
  const hashes = search.slice(search.indexOf("?") + 1).split("&");
  return hashes.reduce((params, hash) => {
    const [key, val] = hash.split("=");
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
}


export default connect(null, { loginOkta })(OktaLogin);