import OktaAuth from "@okta/okta-auth-js";
import Settings from "metabase/lib/settings";
import { SessionApi } from "metabase/services";

export const deleteSession = async () => {
  try {
    await SessionApi.delete();
  } catch (error) {
    if (error.status !== 404) {
      console.error("Problem clearing session", error);
    }
  }
};

export async function clearOktaAuthCredentials() {
  try {
    if (Settings.isOktaEnabled()) {
      const authClient = new OktaAuth({
        clientId: Settings.get("okta-client-id"),
        url: "https://" + Settings.get("okta-domain"),
      });

      await authClient.signOut();
    }
  } catch (error) {
    console.error("Problem clearing Okta Auth credentials", error);
  }
}

export function oktaRedirect(original_url) {
  const authClient = new OktaAuth({
    clientId: Settings.get("okta-client-id"),
    url: "https://" + Settings.get("okta-domain"),
    redirectUri: Settings.get("site-url") + "/auth/okta_login",
    //authorizeUrl: "https://" + Settings.get("okta_client_domain") + "/oauth2/v1/authorize",
    authorizeUrl:
      "https://" + Settings.get("okta-domain") + "/oauth2/default/v1/authorize",
    pcke: false,
    authParams: {
      display: "page",
      responseType: ["code"],
      scopes: ["openid", "email", "profile"],
    },
  });

  authClient.token.getWithRedirect({
    state: original_url,
    nonce: Settings.get("okta-nonce"),
    responseType: ["code"],
    scopes: ["openid", "email", "profile"],
  });
}
