import { t } from "ttag";
import { updateIn } from "icepick";

import {
  PLUGIN_AUTH_PROVIDERS,
  PLUGIN_ADMIN_SETTINGS_UPDATES,
  PLUGIN_IS_PASSWORD_USER,
} from "metabase/plugins";

import MetabaseSettings from "metabase/lib/settings";

import OktaLogin from "metabase/auth/components/OktaLogin";
import SettingsOktaForm from "metabase/admin/settings/components/SettingsOktaForm";

import OktaAuthCard from "metabase/admin/settings/auth/containers/OktaAuthCard";

const OKTA_PROVIDER = {
  name: "okta",
  Button: OktaLogin,
};

PLUGIN_AUTH_PROVIDERS.push(providers =>
  MetabaseSettings.isOktaEnabled() ? [OKTA_PROVIDER, ...providers] : providers,
);

PLUGIN_ADMIN_SETTINGS_UPDATES.push(
  sections =>
    updateIn(sections, ["authentication", "settings"], settings => [
      ...settings,
      {
        key: "okta-enabled",
        description: null,
        noHeader: true,
        widget: OktaAuthCard,
      },
    ]),
  sections => ({
    ...sections,
    "authentication/okta": {
      component: SettingsOktaForm,
      sidebar: false,
      settings: [
        {
          key: "okta-enabled",
          display_name: t`OKTA Authentication`,
          description: null,
          type: "boolean",
        },
        {
          key: "okta-domain",
          display_name: t`DOMAIN`,
          placeholder: "Okta Domain e.g: www.okta-domain.com",
          type: "string",
          required: true,
          autoFocus: true,
        },
        {
          key: "okta-client-id",
          display_name: t`CLIENT ID `,
          placeholder: "Okta client ID",
          type: "string",
          required: true,
        },
        {
          key: "okta-client-secret",
          display_name: t`CLIENT SECRET`,
          placeholder: "Okta client secret",
          type: "string",
          required: true,
        },
      ],
    },
}));

PLUGIN_IS_PASSWORD_USER.push(user => !user.okta_auth);
