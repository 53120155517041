var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var pu,ru,su,tu,uu,vu,wu,Du,Eu,Fu,Gu,Hu,Bba,Ku,Cba,Nu,Ou,Qu,Ru,Su,Tu,Uu,Vu,$u,cv,ev,hv,Dba,Eba,mv,Fba,ov,pv,qv,wv,xv,Fv,Jv,Pv,Rv,Wv,$v,ew,gw,hw,bw,jw,kw,mw,lw,nw,Jba,sw,Bw,Cw,vw,Lba,Gw,Jw,Lw,Mw,Nba,Oba,Pba,Qw,Uw,Vw,Ww,Xw,Qba,bx,ex,Rba,Sba,Tba,Uba,Vba,Wba,Xba,Yba,Zba,aca,jx,$ba,nx,bca,ox,cca,dca,px,eca,qx,fca,gca,hca,jca,kca,mca,nca,pca,rca,vx,yx,tca,sca,uca,aw,dw,Fx,Gx,Hx,Ix,Lx,Mx,xca,yca,zca,Px,Aca,Qx,Ox,Rx,Yu,Sx,lv,dx,Bca,Tv,Cca,Dca,av,jv,Jx,Eca,Hv,rw,rv,Fca,Gca,Ev,Hca,wx,vv,qw,Cv,
fx,Ica,kv,Jca,mx,tx,Tx,Kca,Nw,Vx,Pw,Gv,pw,Xu,sx,Iba,Sv,Av,dv,Lca,iw,Mca,yw,Ow,Ax,Nca,Cx,Hba,Oca,Pca,Qca,Xv,Rca,kx,Vv,Sca,zw,xw,Tca,fv,Uca,wca,Dx,sv,Xx,Sw,Yx,Zx,Dw,Vca,uv,Wca,vca,Xca,Zv,ix,ow,Yca,Tw,Kx,Zca,hx,$ca,ww,iv,ada,Iv,ay,Rw,bda,bv,rx,Uv,Gba,ux,gv,Yw,xx,cx,Zw,ax,lx,Ew,by,cy,cda,Pu,dda,Mu,Wu,eda,tv,fda;pu=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.qu=function(){};
ru=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=ru[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=ru._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IWriter.-flush",a);}return a};su=function(){};tu=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=tu[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=tu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPending.-realized?",a);}return a};
uu=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};vu=function(a,b){return a-b*$CLJS.Vd(a,b)};wu=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=wu[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=wu._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.bb("IMultiFn.-add-method",a);}};
$CLJS.xu=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.hg.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.yu=function(a,b){return $CLJS.lh(a,b)};$CLJS.zu=function(a,b){return new $CLJS.ne(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ee($CLJS.A(c),$CLJS.zu(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.Au=function(a,b){return $CLJS.hf.j(function(c){return c},b,$CLJS.bf(a,b))};Du=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Se.g(Bu,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.ac($CLJS.Cu,"\n")};Eu=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Fu=function(a,b,c){var d=c;for(c=$CLJS.Cf;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.Se.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.be.g(c,b);b=e}};Gu=function(a,b){var c=b;for(b=$CLJS.Cf;;){var d=$CLJS.Se.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Va(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.be.g(b,c);c=d}};
Hu=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
Bba=function(a,b){return $CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.Iu=function(){var a=$CLJS.Cu;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.Iu[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Iu._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("IPrettyFlush.-ppflush",a);}};$CLJS.Ju=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
Ku=function(a,b,c){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,b,c)};Cba=function(a,b){$CLJS.E.g(b,"\n")?(Ku(a,$CLJS.Lu,0),Ku(a,$CLJS.mj,$CLJS.Ju(a,$CLJS.mj)+1)):Ku(a,$CLJS.Lu,$CLJS.Ju(a,$CLJS.Lu)+1);return $CLJS.ac($CLJS.Ju(a,Mu),b)};Nu=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.Ch=d;this.C=1074167808;this.I=0};
Ou=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};Qu=function(a,b){for(b=Pu.h(b);;){if(null==b)return!1;if(a===b)return!0;b=Pu.h(b)}};Ru=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
Su=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Tu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};Uu=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Vu=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
$u=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(Wu.h(k),Xu)){var l=Yu.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),l)}Zu.g(a,k);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,Yu.h(k));k=Yu.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Ad(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.A(b),$CLJS.E.g(Wu.h(k),Xu)||
(d=Yu.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),d)),Zu.g(a,k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,Yu.h(k)),k=Yu.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};cv=function(a,b){var c=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(a))),$CLJS.ik),d;(d=null==c)||(a=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(a))),$CLJS.Lu),b=(b=$CLJS.y(b))?av.h($CLJS.id(b))-bv.h($CLJS.A(b)):0,d=a+b<c);return d};
ev=function(a,b,c){b=$CLJS.q(dv.h(b));return $CLJS.n(b)?b:!cv(a,c)};hv=function(a,b,c){var d=fv.h($CLJS.q($CLJS.q(a))),e=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(a))),$CLJS.ik);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(gv.h(b))>=e-d)?ev(a,b,c):d:e:d};Dba=function(a){var b=$CLJS.A(a),c=iv.h(b);b=$CLJS.y($CLJS.zu(function(d){return!($CLJS.E.g(Wu.h(d),Xu)&&Qu(iv.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b)+1,a))],null)};
Eba=function(a){var b=$CLJS.A(a),c=iv.h(b);return $CLJS.y($CLJS.zu(function(d){var e=iv.h(d);return!($CLJS.E.g(Wu.h(d),Xu)&&($CLJS.E.g(e,c)||Qu(e,c)))},$CLJS.B(a)))};
mv=function(a,b){$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,null);b=iv.h(b);var c=jv.h(b);$CLJS.n(c)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Se.g($CLJS.p,$CLJS.df($CLJS.q(kv.h(b))-$CLJS.D(c)," "));$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Ye(lv.h(b),!0),$CLJS.Ye(dv.h(b),!0),a=Pu.h(b);;)if($CLJS.n(a))$CLJS.Ye(dv.h(a),!0),$CLJS.Ye(lv.h(a),!0),a=Pu.h(a);else break a;return null};
Fba=function(a){var b=$CLJS.y($CLJS.zu(function(c){return!$CLJS.E.g(Wu.h(c),Xu)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.bf($CLJS.D(b),a))],null)};ov=function(a){for(var b=$CLJS.Kt.h($CLJS.q($CLJS.q(a)));;){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Kt,$CLJS.eg.g($CLJS.Cf,b));if(cv(a,b))return null;var c=nv(a,b);if(b!==c)b=c;else return null}};
pv=function(a,b){$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Kt,$CLJS.be.g($CLJS.Kt.h($CLJS.q($CLJS.q(a))),b));return cv(a,$CLJS.Kt.h($CLJS.q($CLJS.q(a))))?null:ov(a)};qv=function(a){var b=Yu.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),b),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,null)):null};
wv=function(a,b){var c=$CLJS.It(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=jv.h($CLJS.A(rv.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.A(c);if($CLJS.E.g(sv,$CLJS.xi.h($CLJS.q($CLJS.q(a))))){var e=tv.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Oh.v($CLJS.q(a),$CLJS.R,tv,f);pv(a,new Ru(uv,d,null,e,f,null,null,null));ov(a);d=$CLJS.Kt.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&($u(a,d,!0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,$CLJS.Kt,$CLJS.Cf))}else qv(a),$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),d);$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.Et(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),l);$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Ad(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.A(e),$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),d),$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Oh.v($CLJS.q(a),
$CLJS.R,sv,vv);return $CLJS.id(c)};xv=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.li=c;this.mh=d;this.Yc=e;this.Hh=f;this.C=1074167808;this.I=0};
$CLJS.Bv=function(a){var b=$CLJS.yv,c=zv,d=new Ou(null,null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),null,null,null,null,null,null,null),e=$CLJS.Xe($CLJS.Ig([rv,fv,Gba,Av,Hba,$CLJS.xi,tv,Yu,Mu,Iba,$CLJS.Kt],[d,c,d,!0,null,vv,0,null,new Nu(a,b,$CLJS.Xe(new $CLJS.h(null,4,[$CLJS.ik,b,$CLJS.Lu,0,$CLJS.mj,0,Mu,a],null)),$CLJS.N),1,$CLJS.Cf]));return new xv(a,b,c,d,e,$CLJS.N)};
Fv=function(a,b){var c=$CLJS.Cu;b=new Ou(rv.h($CLJS.q($CLJS.q(c))),null,$CLJS.Xe(0),$CLJS.Xe(0),$CLJS.Xe(!1),$CLJS.Xe(!1),a,null,b,null,null,null,null);$CLJS.Oh.v($CLJS.q(c),$CLJS.R,rv,b);if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),vv)){qv(c);var d=Cv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Dv):d.call(null,$CLJS.Dv));$CLJS.n(a)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(c))),$CLJS.Lu);$CLJS.Ye(b.tb,c);$CLJS.Ye(b.sb,c)}else d=tv.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Oh.v($CLJS.q(c),$CLJS.R,tv,a),pv(c,new Tu(Ev,b,d,a,null,null,null))};
Jv=function(){var a=$CLJS.Cu,b=rv.h($CLJS.q($CLJS.q(a))),c=Gv.h(b);if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(a))),vv)){qv(a);$CLJS.n(c)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),c);var d=Cv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(Hv):d.call(null,Hv))}else d=tv.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Oh.v($CLJS.q(a),$CLJS.R,tv,c),pv(a,new Uu(Iv,b,d,c,null,null,null));$CLJS.Oh.v($CLJS.q(a),$CLJS.R,rv,Pu.h(b))};
$CLJS.Kv=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.$a($CLJS.qu,a):$CLJS.$a($CLJS.qu,a);return b?Av.h($CLJS.q($CLJS.q(a))):b};$CLJS.Ov=function(a){var b=Lv;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?Lv>=$CLJS.Ma:b);$CLJS.Va($CLJS.Mv)?Bu.call(null,a):$CLJS.n(b)?$CLJS.ac($CLJS.Cu,"..."):($CLJS.n(Lv)&&(Lv+=1),Nv.call(null,a));return b};
Pv=function(a,b){if($CLJS.Va(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};Rv=function(){var a=$CLJS.qh;return $CLJS.n(a)?Qv>=$CLJS.qh:a};Wv=function(a){Pv(a,new $CLJS.ah(null,new $CLJS.h(null,4,[Sv,null,Tv,null,Uv,null,Vv,null],null),null));var b=$CLJS.Cu;$CLJS.Oh.v($CLJS.q(b),$CLJS.R,$CLJS.xi,sv);var c=tv.h($CLJS.q($CLJS.q(b))),d=rv.h($CLJS.q($CLJS.q(b)));pv(b,new Su(Xu,a,d,c,c,null,null,null))};
$v=function(a,b){Pv(a,new $CLJS.ah(null,new $CLJS.h(null,2,[Xv,null,$CLJS.Yv,null],null),null));var c=$CLJS.Cu,d=rv.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),vv)){qv(c);var e=kv.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Xv,a):$CLJS.E.call(null,Xv,a)))a=$CLJS.q(gv.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.Yv,a):$CLJS.E.call(null,$CLJS.Yv,a)))a=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(c))),$CLJS.Lu);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Ye(e,b+a)}else e=
tv.h($CLJS.q($CLJS.q(c))),pv(c,new Vu(Zv,d,a,b,e,e,null,null,null))};ew=function(a,b,c){b="string"===typeof b?aw(b):b;c=bw(c);a:{var d=new $CLJS.Ea,e=$CLJS.Va(a)||!0===a?new $CLJS.sc(d):a,f=cw(b)&&$CLJS.Va($CLJS.Kv(e))?$CLJS.n($CLJS.Kv(e))?e:$CLJS.Bv(e):e,k=$CLJS.Cu;$CLJS.Cu=f;try{try{dw(b,c)}finally{e!==f&&ru(f)}var l=$CLJS.Va(a)?$CLJS.p.h(d):!0===a?$CLJS.uh($CLJS.p.h(d)):null;break a}finally{$CLJS.Cu=k}l=void 0}return l};
gw=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(fw),"\n",$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(b," "))),"^\n"].join("");throw Error(a);};hw=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};bw=function(a){a=$CLJS.y(a);return new hw(a,a,0,null,null,null)};
jw=function(a){var b=$CLJS.Oi.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),new hw(iw.h(a),$CLJS.B(b),tv.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};kw=function(a){var b=jw(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?aw(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};mw=function(a,b){return b>=tv.h(a)?(b=tv.h(a)-b,lw.g?lw.g(a,b):lw.call(null,a,b)):new hw(iw.h(a),$CLJS.bf(b,iw.h(a)),b,null,null,null)};
lw=function(a,b){var c=tv.h(a)+b;return 0>b?mw(a,c):new hw(iw.h(a),$CLJS.bf(b,$CLJS.Oi.h(a)),c,null,null,null)};nw=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Jba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,[ow,null,pw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,qw)?jw(b):$CLJS.E.g(d,rw)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.Oi.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};sw=function(a,b){b=Fu(Jba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eg.g($CLJS.N,a),b],null)};
Bw=function(a,b,c){c=jw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d))if($CLJS.E.g(tw,10))var e=[$CLJS.p.h(d),$CLJS.n(uw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(uw)){var k=$CLJS.J.g(Kba,tw);k=$CLJS.n(k)?k:["#",$CLJS.p.h(tw),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(vw(tw,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+ww.h(b);e=e>=xw.h(b)?e:e+($CLJS.Vd(xw.h(b)-e-1,yw.h(b))+1)*yw.h(b);d=$CLJS.Se.g($CLJS.p,$CLJS.df(e-d,zw.h(b)));
$CLJS.n(pw.h(b))?Aw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Aw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};Cw=function(a,b){return $CLJS.ce($CLJS.A(Gu(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[vu(c,a),$CLJS.Vd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};vw=function(a,b){return 0===b?"0":$CLJS.Se.g($CLJS.p,$CLJS.hf.g(function(c){return 10>c?uu(Eu("0")+c):uu(Eu("a")+(c-10))},Cw(a,b)))};
Lba=function(a,b){return $CLJS.ce($CLJS.A(Gu(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.ce($CLJS.af(a,c))),$CLJS.y($CLJS.bf(a,c))],null)},$CLJS.ce(b))))};
Gw=function(a,b,c){c=jw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=vw(a,e?-d:d);a=$CLJS.n(ow.h(b))?function(){var k=$CLJS.hf.g(function(m){return $CLJS.Se.g($CLJS.p,m)},Lba(Dw.h(b),f)),l=$CLJS.df($CLJS.D(k),Ew.h(b));return $CLJS.Se.g($CLJS.p,$CLJS.B($CLJS.ef.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(pw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
xw.h(b)?[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(xw.h(b)-a.length,zw.h(b)))),$CLJS.p.h(a)].join(""):a;Aw.l($CLJS.H([a]))}else Bw($CLJS.Fw,new $CLJS.h(null,5,[xw,xw.h(b),yw,1,ww,0,zw,zw.h(b),pw,!0],null),bw(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
Jw=function(a){var b=$CLJS.Vd(a,100);a=vu(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(Hw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(Hw,a);else{f=$CLJS.Vd(a,10);var k=vu(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(Iw,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(Hw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
Lw=function(a,b){var c=$CLJS.D(a),d=$CLJS.Cf;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.ff(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(Kw,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.be.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(Kw,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
Mw=function(a,b){b=jw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=Cw(10,c);c=$CLJS.Cf;for(var e=$CLJS.D(d)-1;;)if($CLJS.td(d)){Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.g(0,f)?c:$CLJS.be.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else Gw(10,new $CLJS.h(null,5,[xw,0,zw," ",Ew,",",Dw,3,ow,!0],null),bw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Nba=function(a,b){a=jw(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=Eu(b);b=c&127;c&=128;var d=$CLJS.J.g(Mba,b);0<c&&Aw.l($CLJS.H(["Meta-"]));Aw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(uu(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":uu(b)]));return a};
Oba=function(a,b){var c=jw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Nw.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))ew(!0,"\\o~3,'0o",$CLJS.H([Eu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))ew(!0,"\\u~4,'0x",$CLJS.H([Eu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.ac($CLJS.Cu,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Pba=function(a,b){b=jw(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);Aw.l($CLJS.H([a]));return b};Qw=function(a){a=$CLJS.A(a);return $CLJS.E.g(Ow,a)||$CLJS.E.g(Pw,a)};
Uw=function(a,b,c){return $CLJS.hd(Fu(function(d,e){if(Qw(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=sw(Rw.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=Hu(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,Sw,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Tw.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
Vw=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
Ww=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(Eu(b)>=Eu("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Se.j($CLJS.p,"1",$CLJS.df(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Se.v($CLJS.p,a.substring(0,c),uu(Eu(a.charAt(c))+1),$CLJS.df(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
Xw=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Qba=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
bx=function(a,b){var c=Yw.h(a),d=Zw.h(a);b=jw(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=Vw(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.$w.h(a);f=function(){var x=pw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Va(d)&&$CLJS.D(l)-1<=m,u=Ww(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=Xw(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.A(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?ax.h(a):x}())?Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(c,ax.h(a)))])):Aw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(c-v,zw.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Aw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
ex=function(a,b){b=jw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Vw(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=Yw.h(a),l=Zw.h(a),m=cx.h(a),t=$CLJS.$w.h(a),u=function(){var K=dx.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=pw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),z=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Se.g($CLJS.p,$CLJS.df(m-z.length,"0")):null),z].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Se.g($CLJS.p,$CLJS.df(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=Ww(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);z=$CLJS.I(e,2,null);e=Qba(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Va(z)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?ax.h(a):K}())?Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(k,ax.h(a)))])):Aw.l($CLJS.H([[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(k-G-(f?1:0),zw.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Aw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
Rba=function(a,b,c){var d=jw(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=Vw(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=Yw.h(a),l=Zw.h(a),m=cx.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=bx(new $CLJS.h(null,6,[Yw,k,Zw,d,$CLJS.$w,0,ax,ax.h(a),zw,zw.h(a),pw,pw.h(a)],null),b,c),Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(e," "))])),a):ex(a,b,c)};
Sba=function(a,b){b=jw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=Vw(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=Zw.h(a),l=$CLJS.qk.h(a);d=Yw.h(a);var m=function(){var u=pw.h(a);return $CLJS.n(u)?u:0>c}(),t=Ww(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=Xw(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Aw.l($CLJS.H([[$CLJS.n(function(){var u=ow.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Se.g($CLJS.p,$CLJS.df(d-k,zw.h(a)))),$CLJS.n(function(){var u=$CLJS.Va(ow.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Tba=function(a,b){var c=fx.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):jw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.gx.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.Lt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?Uw(b,c,Sw.h(a)):c};
Uba=function(a,b){var c=jw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.gx.h(a);b=$CLJS.n(b)?$CLJS.hd(d):$CLJS.A(d);return $CLJS.n(b)?Uw(b,c,Sw.h(a)):c};Vba=function(a,b){var c=jw(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.gx.h(a);e=$CLJS.n(d)?$CLJS.A(e):null;return $CLJS.n(d)?$CLJS.n(e)?Uw(e,b,Sw.h(a)):b:c};
Wba=function(a,b){var c=hx.h(a),d=$CLJS.A($CLJS.gx.h(a));d=$CLJS.td(d)?kw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=jw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=bw(e);for(var k=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(tv.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(ow.h(ix.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Uw(b,e,Sw.h(a));if($CLJS.E.g(Ow,$CLJS.A(k)))return d;
f+=1;var l=tv.h(e);e=k;k=l}};Xba=function(a,b){var c=hx.h(a),d=$CLJS.A($CLJS.gx.h(a));d=$CLJS.td(d)?kw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=jw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Va(ow.h(ix.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Uw(b,bw($CLJS.A(e)),bw($CLJS.B(e)));if($CLJS.E.g(Pw,$CLJS.A(k)))return d;f+=1;e=$CLJS.B(e)}};
Yba=function(a,b){var c=hx.h(a),d=$CLJS.A($CLJS.gx.h(a)),e=$CLJS.td(d)?kw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(tv.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(ow.h(ix.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=Uw(b,e,Sw.h(a));if($CLJS.E.g(Ow,$CLJS.A(f)))return $CLJS.hd(f);d+=1;var k=tv.h(e);e=f;f=k}};
Zba=function(a,b){var c=hx.h(a),d=$CLJS.A($CLJS.gx.h(a)),e=$CLJS.td(d)?kw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.td($CLJS.Oi.h(e))&&($CLJS.Va(ow.h(ix.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Oi.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(f),new hw(iw.h(e),$CLJS.B(f),tv.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=Uw(b,bw(e),f);
if($CLJS.E.g(Pw,$CLJS.A(e)))return f;e=f;d+=1}};aca=function(a,b){if($CLJS.n(ow.h(ix.h(a)))){var c=$CLJS.gx.h(a),d=$CLJS.D(c),e=1<d?$CLJS.Yj.h(Rw.h($CLJS.A($CLJS.A(c)))):$CLJS.n(ow.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.Yj.h(Rw.h($CLJS.A($CLJS.F(c,2)))):$CLJS.n(ow.h(a))?")":null;d=jw(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var k=Qv,l=Lv;Qv+=1;Lv=0;try{Fv(e,c),Uw(f,bw(b),Sw.h(a)),Jv()}finally{Lv=l,Qv=k}}a=d}else a=$ba(a,b);return a};
jx=function(a,b,c){for(var d=$CLJS.Cf;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.Ea,k=$CLJS.Cu;$CLJS.Cu=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[Uw(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Cu=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Ow,$CLJS.A(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.hd(b)],null);a=$CLJS.B(a);d=$CLJS.be.g(d,e)}};
$ba=function(a,b){var c=function(){var V=$CLJS.Lt.h(a);return $CLJS.n(V)?jx(V,b,Sw.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var V=kx.h(a);return $CLJS.n(V)?sw(V,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var V=$CLJS.A(lx.h(f));return $CLJS.n(V)?V:0}();var l=function(){var V=$CLJS.A(mx.h(f));return $CLJS.n(V)?V:$CLJS.Ju($CLJS.Cu,$CLJS.ik)}(),m=$CLJS.gx.h(a);k=jx(m,k,Sw.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var V=$CLJS.D(t)-1+($CLJS.n(ow.h(a))?1:0)+($CLJS.n(pw.h(a))?1:0);return 1>V?1:V}();m=$CLJS.Qd($CLJS.Ik,$CLJS.hf.g($CLJS.D,t));var v=xw.h(a),x=ww.h(a),z=yw.h(a),C=m+u*x;v=C<=v?v:v+z*(1+$CLJS.Vd(C-v-1,z));var G=v-m;m=function(){var V=$CLJS.Vd(G,u);return x>V?x:V}();z=G-m*u;m=$CLJS.Se.g($CLJS.p,$CLJS.df(m,zw.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.Ju(Mu.h($CLJS.q($CLJS.q($CLJS.Cu))),$CLJS.Lu)+c+v>l:d)&&Aw.l($CLJS.H([d]));c=z;for(var K=t,S=function(){var V=ow.h(a);
return $CLJS.n(V)?V:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Va(pw.h(a))}();;)if($CLJS.y(K))Aw.l($CLJS.H([[$CLJS.p.h($CLJS.Va(S)?$CLJS.A(K):null),$CLJS.p.h($CLJS.n(function(){var V=S;return $CLJS.n(V)?V:(V=$CLJS.B(K))?V:pw.h(a)}())?m:null),$CLJS.p.h(0<c?zw.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};nx=function(a,b){this.Ca=a;this.Sh=b;this.C=1074135040;this.I=0};bca=function(a){return new nx(a,$CLJS.N)};ox=function(a,b){this.Ca=a;this.Th=b;this.C=1074135040;this.I=0};
cca=function(a){return new ox(a,$CLJS.N)};
dca=function(a,b){var c=$CLJS.A(a);return $CLJS.Se.g($CLJS.p,$CLJS.A(Gu(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?pu(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
px=function(a,b,c){this.Ca=a;this.Jd=b;this.Uh=c;this.C=1074135040;this.I=0};eca=function(a){return new px(a,$CLJS.Xe(!0),$CLJS.N)};qx=function(a,b,c){this.Ca=a;this.vd=b;this.Wh=c;this.C=1074135040;this.I=0};fca=function(a){return new qx(a,$CLJS.Xe(!1),$CLJS.N)};gca=function(a,b){var c=$CLJS.n(ow.h(a))?$CLJS.Yv:Xv;$v(c,$CLJS.qk.h(a));return b};hca=function(a,b){a=$CLJS.n(ow.h(a))?$CLJS.n(pw.h(a))?Sv:Uv:$CLJS.n(pw.h(a))?Tv:Vv;Wv(a);return b};
jca=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(ica.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?gw("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};kca=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?qw:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?rw:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
mca=function(a,b){return Gu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g(lca,$CLJS.A(d));return $CLJS.n(f)?$CLJS.Hd(c,f)?gw(['Flag "',$CLJS.p.h($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
nca=function(a,b){var c=rx.h(a);$CLJS.n(function(){var d=$CLJS.Va(pw.h(c));return d?pw.h(b):d}())&&gw(['"@" is an illegal flag for format directive "',$CLJS.p.h(sx.h(a)),'"'].join(""),$CLJS.F(pw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(ow.h(c));return d?ow.h(b):d}())&&gw(['":" is an illegal flag for format directive "',$CLJS.p.h(sx.h(a)),'"'].join(""),$CLJS.F(ow.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(tx.h(c));return d?(d=pw.h(b),$CLJS.n(d)?ow.h(b):d):d}())&&gw(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(sx.h(a)),'"'].join(""),function(){var d=$CLJS.F(ow.h(b),1),e=$CLJS.F(pw.h(b),1);return d<e?d:e}())};
pca=function(a,b,c,d){nca(a,c);$CLJS.D(b)>$CLJS.D(Rw.h(a))&&gw(ew(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([sx.h(a),$CLJS.D(b),$CLJS.D(Rw.h(a))])),$CLJS.hd($CLJS.A(b)));$CLJS.mh($CLJS.hf.j(function(e,f){var k=$CLJS.A(e);return null==k||$CLJS.Hd(oca,k)||$CLJS.E.g($CLJS.hd($CLJS.hd(f)),$CLJS.ab(k))?null:gw(["Parameter ",$CLJS.gh($CLJS.A(f)),' has bad type in directive "',$CLJS.p.h(sx.h(a)),'": ',$CLJS.p.h($CLJS.ab(k))].join(""),
$CLJS.hd(e))},b,Rw.h(a)));return $CLJS.fl.l($CLJS.H([$CLJS.eg.g($CLJS.N,$CLJS.ce(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[z,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):
$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Rw.h(a))}())),$CLJS.fb(function(e,f){return $CLJS.Se.j($CLJS.R,e,f)},$CLJS.N,$CLJS.lf(function(e){return $CLJS.A($CLJS.F(e,1))},$CLJS.xu($CLJS.Xg(Rw.h(a)),b))),c]))};
rca=function(a,b){b=Gu(jca,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=mca(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.A(d);var f=$CLJS.J.g(qca,c.toUpperCase()),k=$CLJS.n(f)?pca(f,$CLJS.hf.g(kca,a),b,e):null;$CLJS.Va(c)&&gw("Format string ended in the middle of a directive",e);$CLJS.Va(f)&&gw(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new nw(function(){var l=ux.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",sx.h(f))&&$CLJS.Va(ow.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.fh(t):$CLJS.ch([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Va(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};vx=function(a,b){return new nw(function(c,d){Aw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Yj,a],null),b,null,null,null)};yx=function(a,b){var c=sca(wx.h(xx.h(a)),$CLJS.Mt.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new nw(Tw.h(a),xx.h(a),$CLJS.fl.l($CLJS.H([Rw.h(a),Bba(b,$CLJS.Mt.h(a))])),$CLJS.Mt.h(a),null,null,null),c],null)};
tca=function(a,b,c){return Gu(function(d){if($CLJS.td(d))return gw("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.n($CLJS.zx.h(wx.h(xx.h(e)))))e=yx(e,d);else if($CLJS.E.g($CLJS.zx.h(a),sx.h(xx.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Ax,Rw.h(e),null,d],null)],null);else{var f=$CLJS.Nt.h(wx.h(xx.h(e)));f=$CLJS.n(f)?ow.h(Rw.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Lt,null,Rw.h(e),d],null)],null):
$CLJS.n($CLJS.Nt.h(wx.h(xx.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Nt,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
sca=function(a,b,c){return $CLJS.hd(Gu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=tca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,Ax)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bx.l($CLJS.gf,$CLJS.H([e,$CLJS.Ee([$CLJS.n(f)?$CLJS.Lt:$CLJS.gx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),ix,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.Lt)?
$CLJS.n($CLJS.Lt.h(e))?gw('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Va($CLJS.Lt.h(a))?gw('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(Cx,$CLJS.Lt.h(a))&&$CLJS.y($CLJS.gx.h(e))?gw('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(Cx,$CLJS.Lt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.Lt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),kx,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.gx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.Nt)?$CLJS.n(f)?gw('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Va(Dx.h(a))?gw('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Bx.l($CLJS.gf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.gx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.gx,$CLJS.Cf],null),!1,c],null)))};uca=function(a){return $CLJS.A(Gu(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=wx.h(xx.h(c));return $CLJS.n($CLJS.zx.h(d))?yx(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
aw=function(a){var b=fw;fw=a;try{return uca($CLJS.A(Gu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[vx(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?rca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[vx(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{fw=
b}};dw=function(a,b){Fu(function(c,d){if(Qw(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=sw(Rw.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=Hu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,Sw,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Se.g(Tw.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
Fx=function(a){var b=$CLJS.A(a);b=Ex.h?Ex.h(b):Ex.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.ac($CLJS.Cu,b),$CLJS.Ov($CLJS.hd(a)),!0):null};
Gx=function(a){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var b=Qv,c=Lv;Qv+=1;Lv=0;try{Fv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Ov($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Cu," ");Wv(Vv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Cu,"...");break}Jv()}finally{Lv=c,Qv=b}}return null};
Hx=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Hh},vca,$CLJS.Ig([$CLJS.vk,$CLJS.Vi,$CLJS.T,$CLJS.ok,$CLJS.ui,$CLJS.Si,$CLJS.mj,$CLJS.xk,$CLJS.ti,$CLJS.jk,$CLJS.nk],[!0,$CLJS.li,wca,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dj],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Hh)?$CLJS.Hh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{c=Qv;b=Lv;Qv+=1;Lv=0;try{Fv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Va($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{d=Qv;var k=Lv;Qv+=1;Lv=0;try{Fv(null,null),$CLJS.Ov($CLJS.A($CLJS.A(f))),$CLJS.ac($CLJS.Cu," "),Wv(Vv),Lv=0,$CLJS.Ov($CLJS.A($CLJS.B($CLJS.A(f)))),Jv()}finally{Lv=k,Qv=d}}if($CLJS.B(f)){$CLJS.ac($CLJS.Cu,", ");Wv(Vv);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.ac($CLJS.Cu,
"...");break}Jv()}finally{Lv=b,Qv=c}}return null};Ix=function(a){return $CLJS.ac($CLJS.Cu,$CLJS.Mh.l($CLJS.H([a])))};Lx=function(a){return a instanceof $CLJS.Rf?Jx:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.$a($CLJS.qu,a)):$CLJS.$a($CLJS.qu,a))?Kx:a instanceof $CLJS.r?$CLJS.Gi:$CLJS.Dd(a)?$CLJS.Bj:$CLJS.xd(a)?$CLJS.hk:$CLJS.zd(a)?$CLJS.Jj:$CLJS.vd(a)?$CLJS.Zi:null==a?null:$CLJS.ci};
Mx=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
xca=function(a){if($CLJS.wd(a)){var b=Mx(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.A(e),k=$CLJS.B(e);if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var l=Qv,m=Lv,t=Qv+1;Qv=t;Lv=0;try{Fv(c,d);(function(){var Na=Nx("~w~:i");return function(){function zb(Za){var eb=null;if(0<arguments.length){eb=0;for(var Sa=Array(arguments.length-0);eb<Sa.length;)Sa[eb]=arguments[eb+0],++eb;eb=new $CLJS.w(Sa,0,null)}return Pa.call(this,eb)}function Pa(Za){Za=bw(Za);return dw(Na,Za)}zb.A=0;zb.B=function(Za){Za=
$CLJS.y(Za);return Pa(Za)};zb.l=Pa;return zb}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Na=Nx(" ");return function(zb,Pa,Za){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=bw(mb);return dw(Za,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u," ",Na,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.A(u);if($CLJS.wd(v)){var x=Mx(v),z=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var G=Qv,K=Lv,S=Qv+1;Qv=S;Lv=0;try{Fv(z,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.hd(v)instanceof $CLJS.M){var V=v,Z=$CLJS.I(V,0,null),ha=$CLJS.I(V,1,null),ra=$CLJS.I(V,2,null);(function(){var Na=Nx("~w ~w ");return function(zb,Pa,Za){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+
0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=bw(mb);return dw(Za,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~w ",Na,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(Z,ha);$CLJS.wd(ra)?function(){var Na=$CLJS.zd(ra)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",zb="string"===typeof Na?Nx(Na):Na;return function(Pa,Za,eb){return function(){function Sa(Ja){var bc=null;if(0<arguments.length){bc=0;for(var $d=
Array(arguments.length-0);bc<$d.length;)$d[bc]=arguments[bc+0],++bc;bc=new $CLJS.w($d,0,null)}return mb.call(this,bc)}function mb(Ja){Ja=bw(Ja);return dw(eb,Ja)}Sa.A=0;Sa.B=function(Ja){Ja=$CLJS.y(Ja);return mb(Ja)};Sa.l=mb;return Sa}()}(u,Na,zb,V,Z,ha,ra,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(ra):$CLJS.Ov(ra)}else $CLJS.Se.g(function(){var Na=Nx("~w ~:i~@{~w~^ ~:_~}");return function(zb,Pa,Za){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-
0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=bw(mb);return dw(Za,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Na,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);Jv()}finally{Lv=K,Qv=G}}$CLJS.B(u)&&function(){var Na=Nx("~_");return function(zb,Pa,Za){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=
arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,Ja)}function Sa(mb){mb=bw(mb);return dw(Za,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~_",Na,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Ov(v),$CLJS.B(u)&&function(){var Na=Nx("~:_");return function(zb,Pa,Za){return function(){function eb(mb){var Ja=null;if(0<arguments.length){Ja=0;for(var bc=Array(arguments.length-0);Ja<bc.length;)bc[Ja]=arguments[Ja+0],++Ja;Ja=new $CLJS.w(bc,0,null)}return Sa.call(this,
Ja)}function Sa(mb){mb=bw(mb);return dw(Za,mb)}eb.A=0;eb.B=function(mb){mb=$CLJS.y(mb);return Sa(mb)};eb.l=Sa;return eb}()}(u,"~:_",Na,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;Jv()}finally{Lv=m,Qv=l}}}else $CLJS.Ov(a)};
yca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=Nx(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=bw(f);return dw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=Nx(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=bw(f);return dw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=Nx("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=bw(f);return dw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};zca=function(a){$CLJS.y(a)&&function(){var b=Nx(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=bw(e);return dw(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
Px=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var f=
Qv,k=Lv;Qv+=1;Lv=0;try{Fv("(",")"),function(){var l=Nx("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=bw(u);return dw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=Nx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=bw(u);return dw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=Nx(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=bw(u);return dw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.A(e))?yca(e,$CLJS.n(c)?c:d):zca(e),Jv()}finally{Lv=k,Qv=f}}return null}return Ox(a)};
Aca=function(a){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var b=Qv,c=Lv;Qv+=1;Lv=0;try{Fv("[","]");for(var d=0;;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.y(a)){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var e=Qv,f=Lv;Qv+=1;Lv=0;try{Fv(null,null),$CLJS.Ov($CLJS.A(a)),$CLJS.B(a)&&($CLJS.ac($CLJS.Cu," "),Wv(Tv),$CLJS.Ov($CLJS.hd(a))),Jv()}finally{Lv=f,Qv=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.ac($CLJS.Cu," ");Wv(Vv);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.ac($CLJS.Cu,"...");
break}Jv()}finally{Lv=c,Qv=b}}};
Qx=function(a){var b=$CLJS.A(a);if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var c=Qv,d=Lv;Qv+=1;Lv=0;try{Fv("(",")"),$CLJS.B(a)&&$CLJS.zd($CLJS.hd(a))?(function(){var e=Nx("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=bw(l);return dw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),Aca($CLJS.hd(a)),function(){var e=
Nx(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=bw(l);return dw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):Ox(a),Jv()}finally{Lv=d,Qv=c}}return null};
Ox=function(a){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var b=Qv,c=Lv;Qv+=1;Lv=0;try{Fv("(",")");$v(Xv,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Ov($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Cu," ");Wv(Vv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Cu,"...");break}Jv()}finally{Lv=c,Qv=b}}return null};$CLJS.ne.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Va(this.Rb)});$CLJS.cf.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Jh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Va(this.f)});$CLJS.Cu=null;Rx=function Rx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Rx.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};Rx.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.gg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.hg.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.gc(b)};
Rx.A=0;Rx.B=function(a){return this.l($CLJS.y(a))};$CLJS.Bx=function Bx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Bx.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.Bx.l=function(a,b){if($CLJS.n($CLJS.Re($CLJS.Td,b))){var c=function(d,e){var f=$CLJS.Eb(e),k=$CLJS.Fb(e);if($CLJS.Hd(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Qd(function(d,e){return $CLJS.fb(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.Bx.A=1;$CLJS.Bx.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.Fw=function Fw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Fw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.Fw.l=function(a){return $CLJS.Eh(a,$CLJS.R.j($CLJS.Oa(),$CLJS.Ga,!1))};$CLJS.Fw.A=0;$CLJS.Fw.B=function(a){return this.l($CLJS.y(a))};Yu=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Sx=new $CLJS.M(null,"relative-to","relative-to",-470100051);lv=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
dx=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);Bca=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Tv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.Yv=new $CLJS.M(null,"current","current",-1088038603);Cca=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);Dca=new $CLJS.r(null,"..","..",-300507420,null);av=new $CLJS.M(null,"end-pos","end-pos",-1643883926);jv=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Jx=new $CLJS.M(null,"queue","queue",1455835879);
Eca=new $CLJS.r(null,"if-let","if-let",1803593690,null);Hv=new $CLJS.M(null,"end","end",-268185958);rw=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);rv=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);Fca=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);Gca=new $CLJS.r(null,"when-first","when-first",821699168,null);Ev=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);Hca=new $CLJS.r(null,"binding","binding",-2114503176,null);
wx=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);vv=new $CLJS.M(null,"writing","writing",-1486865108);qw=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Cv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);fx=new $CLJS.M(null,"selector","selector",762528866);Ica=new $CLJS.r(null,"struct","struct",325972931,null);kv=new $CLJS.M(null,"indent","indent",-148200125);Jca=new $CLJS.r(null,"loop","loop",1244978678,null);
mx=new $CLJS.M(null,"max-columns","max-columns",1742323262);tx=new $CLJS.M(null,"both","both",-393648840);Tx=new $CLJS.M(null,"colnum","colnum",2023796854);Kca=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.Ux=new $CLJS.M(null,"length","length",588987862);Nw=new $CLJS.M(null,"char-format","char-format",-1016499218);Vx=new $CLJS.M(null,"prefix","prefix",-265908465);Pw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);Gv=new $CLJS.M(null,"suffix","suffix",367373057);
pw=new $CLJS.M(null,"at","at",1476951349);Xu=new $CLJS.M(null,"nl-t","nl-t",-1608382114);sx=new $CLJS.M(null,"directive","directive",793559132);Iba=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Sv=new $CLJS.M(null,"mandatory","mandatory",542802336);Av=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);dv=new $CLJS.M(null,"done-nl","done-nl",-381024340);Lca=new $CLJS.r(null,"condp","condp",1054325175,null);iw=new $CLJS.M(null,"seq","seq",-1817803783);
Mca=new $CLJS.r(null,"defn","defn",-126010802,null);yw=new $CLJS.M(null,"colinc","colinc",-584873385);Ow=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Ax=new $CLJS.M(null,"right-bracket","right-bracket",951856080);Nca=new $CLJS.M(null,"radix","radix",857016463);Cx=new $CLJS.M(null,"first","first",-644103046);Hba=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Dv=new $CLJS.M(null,"start","start",-355208981);Oca=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Pca=new $CLJS.r(null,"defn-","defn-",1097765044,null);Qca=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);Xv=new $CLJS.M(null,"block","block",664686210);Rca=new $CLJS.r(null,"when","when",1064114221,null);kx=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.Wx=new $CLJS.M(null,"count","count",2139924085);$CLJS.zx=new $CLJS.M(null,"right","right",-452581833);Vv=new $CLJS.M(null,"linear","linear",872268697);Sca=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
zw=new $CLJS.M(null,"padchar","padchar",2018584530);xw=new $CLJS.M(null,"mincol","mincol",1230695445);Tca=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);fv=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Uca=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);wca=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);Dx=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);sv=new $CLJS.M(null,"buffering","buffering",-876713613);
Xx=new $CLJS.M(null,"arg1","arg1",951899358);Sw=new $CLJS.M(null,"base-args","base-args",-1268706822);Yx=new $CLJS.M(null,"arg3","arg3",-1486822496);Zx=new $CLJS.M(null,"arg2","arg2",1729550917);Dw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);Vca=new $CLJS.M(null,"right-margin","right-margin",-810413306);uv=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);Wca=new $CLJS.r(null,"with-open","with-open",172119667,null);
vca=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);Xca=new $CLJS.M(null,"lines","lines",-700165781);Zv=new $CLJS.M(null,"indent-t","indent-t",528318969);ix=new $CLJS.M(null,"right-params","right-params",-1790676237);ow=new $CLJS.M(null,"colon","colon",-965200945);Yca=new $CLJS.r(null,"if-not","if-not",-265415609,null);Tw=new $CLJS.M(null,"func","func",-238706040);$CLJS.$x=new $CLJS.M(null,"last","last",1105735132);Kx=new $CLJS.M(null,"deref","deref",-145586795);
Zca=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);hx=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);$ca=new $CLJS.r(null,"cond","cond",1606708055,null);ww=new $CLJS.M(null,"minpad","minpad",323570901);iv=new $CLJS.M(null,"logical-block","logical-block",-581022564);ada=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);Iv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);ay=new $CLJS.M(null,"stream","stream",1534941648);
Rw=new $CLJS.M(null,"params","params",710516235);bda=new $CLJS.M(null,"circle","circle",1903212362);bv=new $CLJS.M(null,"start-pos","start-pos",668789086);rx=new $CLJS.M(null,"flags","flags",1775418075);Uv=new $CLJS.M(null,"fill","fill",883462889);Gba=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);ux=new $CLJS.M(null,"generator-fn","generator-fn",811851656);gv=new $CLJS.M(null,"start-col","start-col",668080143);Yw=new $CLJS.M(null,"w","w",354169001);xx=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.$w=new $CLJS.M(null,"k","k",-2146297393);cx=new $CLJS.M(null,"e","e",1381269198);Zw=new $CLJS.M(null,"d","d",1972142424);ax=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);lx=new $CLJS.M(null,"min-remaining","min-remaining",962687677);Ew=new $CLJS.M(null,"commachar","commachar",652859327);by=new $CLJS.M(null,"section","section",-300141526);cy=new $CLJS.M(null,"pretty","pretty",-1916372486);cda=new $CLJS.r(null,"let","let",358118826,null);Pu=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.gx=new $CLJS.M(null,"clauses","clauses",1454841241);dda=new $CLJS.r(null,"defonce","defonce",-1681484013,null);Mu=new $CLJS.M(null,"base","base",185279322);Wu=new $CLJS.M(null,"type-tag","type-tag",-1873863267);eda=new $CLJS.r(null,"locking","locking",1542862874,null);tv=new $CLJS.M(null,"pos","pos",-864607220);fda=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.Lu=new $CLJS.M(null,"cur","cur",1153190599);var Aw=function Aw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Aw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},Nv,zv,ey,fy,gy,uw,tw,Qv,Lv,hy;Aw.l=function(a){return $CLJS.ac($CLJS.Cu,$CLJS.Se.g($CLJS.Fw,a))};Aw.A=0;Aw.B=function(a){return this.l($CLJS.y(a))};var Bu=function Bu(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Bu.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
Bu.l=function(a){return $CLJS.ac($CLJS.Cu,$CLJS.Se.g($CLJS.Mh,a))};Bu.A=0;Bu.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=Nu.prototype;$CLJS.g.P=function(a,b){return new Nu(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.Ch};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return ru(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?Ku(this,$CLJS.Lu,$CLJS.Ju(this,$CLJS.Lu)+$CLJS.D(b)):(Ku(this,$CLJS.Lu,$CLJS.D(b)-a-1),Ku(this,$CLJS.mj,$CLJS.Ju(this,$CLJS.mj)+$CLJS.D($CLJS.lf(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.ac($CLJS.Ju(this,Mu),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return Cba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=Ou.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Pu,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[by,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[gv,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[kv,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[lv,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[Vx,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[jv,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[Gv,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[Cv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Ft(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[Pu,by,gv,kv,dv,lv,Vx,jv,Gv,Cv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,10,[Gv,null,kv,null,Pu,null,by,null,dv,null,gv,null,Vx,null,jv,null,Cv,null,lv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Pu,b):$CLJS.he.call(null,Pu,b))?new Ou(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(by,b):$CLJS.he.call(null,by,b))?new Ou(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(gv,b):$CLJS.he.call(null,gv,b))?new Ou(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(kv,b):$CLJS.he.call(null,kv,b))?new Ou(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(dv,b):$CLJS.he.call(null,dv,b))?new Ou(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(lv,b):$CLJS.he.call(null,lv,b))?new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Vx,b):$CLJS.he.call(null,Vx,b))?new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(jv,b):$CLJS.he.call(null,jv,b))?new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Gv,b):$CLJS.he.call(null,Gv,b))?new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Cv,b):$CLJS.he.call(null,Cv,b))?new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Yf(Pu,this.parent),new $CLJS.Yf(by,this.Cb),new $CLJS.Yf(gv,this.tb),new $CLJS.Yf(kv,this.sb),new $CLJS.Yf(dv,this.vb),new $CLJS.Yf(lv,this.wb),new $CLJS.Yf(Vx,this.prefix),new $CLJS.Yf(jv,this.Ab),new $CLJS.Yf(Gv,this.Db),new $CLJS.Yf(Cv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new Ou(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Ru.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[Yu,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[av,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ft(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Wu,$CLJS.qj,Yu,bv,av],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[av,null,Yu,null,Wu,null,bv,null,$CLJS.qj,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Ru(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Wu,b):$CLJS.he.call(null,Wu,b))?new Ru(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.qj,b):$CLJS.he.call(null,$CLJS.qj,b))?new Ru(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Yu,b):$CLJS.he.call(null,Yu,b))?new Ru(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Ru(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Ru(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new Ru(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(Wu,this.ca),new $CLJS.Yf($CLJS.qj,this.data),new $CLJS.Yf(Yu,this.ac),new $CLJS.Yf(bv,this.ba),new $CLJS.Yf(av,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Ru(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Su.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[iv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[av,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ft(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Wu,$CLJS.lj,iv,bv,av],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,5,[av,null,$CLJS.lj,null,iv,null,Wu,null,bv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Su(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Wu,b):$CLJS.he.call(null,Wu,b))?new Su(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.lj,b):$CLJS.he.call(null,$CLJS.lj,b))?new Su(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(iv,b):$CLJS.he.call(null,iv,b))?new Su(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Su(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Su(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new Su(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Yf(Wu,this.ca),new $CLJS.Yf($CLJS.lj,this.type),new $CLJS.Yf(iv,this.ea),new $CLJS.Yf(bv,this.ba),new $CLJS.Yf(av,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new Su(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Tu.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[iv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[av,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ft(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Wu,iv,bv,av],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[av,null,iv,null,Wu,null,bv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Tu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Wu,b):$CLJS.he.call(null,Wu,b))?new Tu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(iv,b):$CLJS.he.call(null,iv,b))?new Tu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Tu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Tu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Tu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Wu,this.ca),new $CLJS.Yf(iv,this.ea),new $CLJS.Yf(bv,this.ba),new $CLJS.Yf(av,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Tu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Uu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[iv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[av,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ft(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Wu,iv,bv,av],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[av,null,iv,null,Wu,null,bv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Uu(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Wu,b):$CLJS.he.call(null,Wu,b))?new Uu(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(iv,b):$CLJS.he.call(null,iv,b))?new Uu(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Uu(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Uu(this.ca,this.ea,this.ba,c,this.S,this.G,null):new Uu(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Wu,this.ca),new $CLJS.Yf(iv,this.ea),new $CLJS.Yf(bv,this.ba),new $CLJS.Yf(av,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Uu(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=Vu.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[iv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[bv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[av,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ft(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[Wu,iv,Sx,$CLJS.Mt,bv,av],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,6,[$CLJS.Mt,null,av,null,Sx,null,iv,null,Wu,null,bv,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new Vu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Wu,b):$CLJS.he.call(null,Wu,b))?new Vu(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(iv,b):$CLJS.he.call(null,iv,b))?new Vu(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Sx,b):$CLJS.he.call(null,Sx,b))?new Vu(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Mt,b):$CLJS.he.call(null,$CLJS.Mt,
b))?new Vu(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(bv,b):$CLJS.he.call(null,bv,b))?new Vu(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(av,b):$CLJS.he.call(null,av,b))?new Vu(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new Vu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Yf(Wu,this.ca),new $CLJS.Yf(iv,this.ea),new $CLJS.Yf(Sx,this.Wb),new $CLJS.Yf($CLJS.Mt,this.offset),new $CLJS.Yf(bv,this.ba),new $CLJS.Yf(av,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new Vu(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var Zu=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("cljs.pprint","write-token"),function(f,k){return Wu.h(k)},e,a,b,c,d)}();
Zu.m(null,Ev,function(a,b){var c=Cv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Dv):c.call(null,$CLJS.Dv));b=iv.h(b);c=Vx.h(b);$CLJS.n(c)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(a))),$CLJS.Lu);$CLJS.Ye(gv.h(b),a);return $CLJS.Ye(kv.h(b),a)});Zu.m(null,Iv,function(a,b){var c=Cv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(Hv):c.call(null,Hv));b=Gv.h(iv.h(b));return $CLJS.n(b)?$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),b):null});
Zu.m(null,Zv,function(a,b){var c=iv.h(b),d=kv.h(c),e=$CLJS.Mt.h(b);b=Sx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Xv,b):$CLJS.E.call(null,Xv,b)))a=$CLJS.q(gv.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.Yv,b):$CLJS.E.call(null,$CLJS.Yv,b)))a=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q(a))),$CLJS.Lu);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Ye(d,e+a)});Zu.m(null,uv,function(a,b){return $CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),$CLJS.qj.h(b))});
Zu.m(null,Xu,function(a,b){var c=$CLJS.E.g($CLJS.lj.h(b),Sv);c||(c=(c=!$CLJS.E.g($CLJS.lj.h(b),Uv))?$CLJS.q(dv.h(iv.h(b))):c);$CLJS.n(c)?mv.g?mv.g(a,b):mv.call(null,a,b):(b=Yu.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.ac(Mu.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Oh.v($CLJS.q(a),$CLJS.R,Yu,null)});
var dy=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.lj.h(f)},e,a,b,c,d)}();dy.m(null,Vv,function(a,b,c){a=iv.h(a);return ev(b,a,c)});dy.m(null,Tv,function(a,b,c){a=iv.h(a);return hv(b,a,c)});
dy.m(null,Uv,function(a,b,c,d){a=iv.h(a);var e=$CLJS.q(lv.h(a));return $CLJS.n(e)?e:(d=!cv(b,d))?d:hv(b,a,c)});dy.m(null,Sv,function(){return!0});
var nv=function nv(a,b){var d=Fba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&$u(a,b,!1);if($CLJS.n(e)){d=Dba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.A(e),k=dy.v(f,a,b,Eba(e));$CLJS.n(k)&&(mv(a,f),e=$CLJS.B(e));cv(a,e)?a=e:(e=nv.g?nv.g(a,b):nv.call(null,a,b),$CLJS.E.g(e,b)?($u(a,b,!1),a=d):a=$CLJS.eg.g($CLJS.Cf,$CLJS.gf.g(e,d)));return a}return null};$CLJS.g=xv.prototype;$CLJS.g.P=function(a,b){return new xv(this.Ca,this.Nf,this.li,this.mh,this.Yc,b)};$CLJS.g.O=function(){return this.Hh};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=wv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.xi.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,vv))return qv(this),$CLJS.ac(Mu.h($CLJS.q($CLJS.q(this))),b),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,Yu,a);d=tv.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Oh.v($CLJS.q(this),$CLJS.R,tv,c);return pv(this,new Ru(uv,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),vv)?(qv(this),b=$CLJS.ac(Mu.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=wv(this,"\n"):(a=tv.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Oh.v($CLJS.q(this),$CLJS.R,tv,c),b=uu(b),b=pv(this,new Ru(uv,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return ru(Mu.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),sv)?($u(this,$CLJS.Kt.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Oh.v($CLJS.q(this),$CLJS.R,$CLJS.Kt,$CLJS.Cf)):qv(this)};$CLJS.Mv=!0;Nv=null;$CLJS.yv=72;zv=40;ey=null;fy=null;gy=null;uw=null;tw=10;Qv=0;Lv=null;hy=function hy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return hy.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
hy.l=function(a,b){var c=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[ay,!0],null),$CLJS.Se.g(Rx,b)]));b=tw;var d=fy,e=$CLJS.Ma,f=$CLJS.qh,k=ey,l=zv,m=Nv,t=$CLJS.Mv,u=uw,v=$CLJS.Ha,x=$CLJS.yv,z=gy,C=Mu.g(c,tw),G=bda.g(c,fy),K=$CLJS.Ux.g(c,$CLJS.Ma),S=$CLJS.Ot.g(c,$CLJS.qh),V=Xca.g(c,ey),Z=fv.g(c,zv),ha=$CLJS.Ni.g(c,Nv),ra=cy.g(c,$CLJS.Mv),Na=Nca.g(c,uw),zb=$CLJS.Ga.g(c,$CLJS.Ha),Pa=Vca.g(c,$CLJS.yv),Za=fda.g(c,gy);tw=C;fy=G;$CLJS.Ma=K;$CLJS.qh=S;ey=V;zv=Z;Nv=ha;$CLJS.Mv=ra;uw=Na;$CLJS.Ha=zb;$CLJS.yv=Pa;
gy=Za;try{var eb=new $CLJS.Ea,Sa=$CLJS.Hd(c,ay)?ay.h(c):!0,mb=!0===Sa||null==Sa?new $CLJS.sc(eb):Sa;if($CLJS.n($CLJS.Mv)){var Ja=$CLJS.Va($CLJS.Kv(mb));c=$CLJS.Cu;$CLJS.Cu=Ja?$CLJS.Bv(mb):mb;try{$CLJS.Ov(a),$CLJS.Iu()}finally{$CLJS.Cu=c}}else{Ja=$CLJS.Cu;$CLJS.Cu=mb;try{Bu.call(null,a)}finally{$CLJS.Cu=Ja}}!0===Sa&&$CLJS.uh($CLJS.p.h(eb));return null==Sa?$CLJS.p.h(eb):null}finally{gy=z,$CLJS.yv=x,$CLJS.Ha=v,uw=u,$CLJS.Mv=t,Nv=m,zv=l,ey=k,$CLJS.qh=f,$CLJS.Ma=e,fy=d,tw=b}};hy.A=1;
hy.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var fw=null;$CLJS.g=hw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[iw,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[tv,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Ft(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[iw,$CLJS.Oi,tv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,3,[tv,null,iw,null,$CLJS.Oi,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new hw(this.uc,this.pb,this.$b,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(iw,b):$CLJS.he.call(null,iw,b))?new hw(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Oi,b):$CLJS.he.call(null,$CLJS.Oi,b))?new hw(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(tv,b):$CLJS.he.call(null,tv,b))?new hw(this.uc,this.pb,c,this.S,this.G,null):new hw(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Yf(iw,this.uc),new $CLJS.Yf($CLJS.Oi,this.pb),new $CLJS.Yf(tv,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new hw(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};$CLJS.g=nw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Tw,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[xx,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rw,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Ft(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Tw,xx,Rw,$CLJS.Mt],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Mt,null,Tw,null,Rw,null,xx,null],null),null),b)?$CLJS.Gk.g($CLJS.Ob($CLJS.eg.g($CLJS.N,this),this.S),b):new nw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Fe($CLJS.Gk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.he.g?$CLJS.he.g(Tw,b):$CLJS.he.call(null,Tw,b))?new nw(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(xx,b):$CLJS.he.call(null,xx,b))?new nw(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g(Rw,b):$CLJS.he.call(null,Rw,b))?new nw(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.he.g?$CLJS.he.g($CLJS.Mt,b):$CLJS.he.call(null,$CLJS.Mt,b))?new nw(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new nw(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.gf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Yf(Tw,this.dc),new $CLJS.Yf(xx,this.cc),new $CLJS.Yf(Rw,this.kc),new $CLJS.Yf($CLJS.Mt,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new nw(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
var Kba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),Hw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),iy=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),Iw=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),gda=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),Kw=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),hda=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),ida=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Mba=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);nx.prototype.P=function(a,b){return new nx(this.Ca,b)};nx.prototype.O=function(){return this.Sh};nx.prototype.rc=function(){return ru(this.Ca)};
nx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,uu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};ox.prototype.P=function(a,b){return new ox(this.Ca,b)};ox.prototype.O=function(){return this.Th};ox.prototype.rc=function(){return ru(this.Ca)};
ox.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.ac(this.Ca,uu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};px.prototype.P=function(a,b){return new px(this.Ca,this.Jd,b)};px.prototype.O=function(){return this.Uh};px.prototype.rc=function(){return ru(this.Ca)};
px.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.ac(this.Ca,dca(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.Ye(this.Jd,$CLJS.Aa($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=uu(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.ac(this.Ca,a),$CLJS.Ye(this.Jd,$CLJS.Aa(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
qx.prototype.P=function(a,b){return new qx(this.Ca,this.vd,b)};qx.prototype.O=function(){return this.Wh};qx.prototype.rc=function(){return ru(this.Ca)};
qx.prototype.Mc=function(a,b){a=$CLJS.ab(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Va($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.ac(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Ye(this.vd,!0)):$CLJS.ac(this.Ca,b)):$CLJS.ac(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=uu(b),a=$CLJS.Va($CLJS.q(this.vd)),
$CLJS.n(a?pu(b):a)?($CLJS.Ye(this.vd,!0),$CLJS.ac(this.Ca,b.toUpperCase())):$CLJS.ac(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var qca=$CLJS.Ig("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[sx,"A",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),yw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ww,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){return Bw($CLJS.Fw,a,b)}}],null),new $CLJS.h(null,
5,[sx,"S",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),yw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ww,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){return Bw($CLJS.Mh,a,b)}}],null),new $CLJS.h(null,5,[sx,"D",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){return Gw(10,a,b)}}],null),new $CLJS.h(null,5,[sx,"B",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Dw,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){return Gw(2,a,b)}}],null),new $CLJS.h(null,5,[sx,"O",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,
null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){return Gw(8,a,b)}}],null),new $CLJS.h(null,5,[sx,"X",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){return Gw(16,a,b)}}],null),new $CLJS.h(null,
5,[sx,"R",Rw,new $CLJS.h(null,5,[Mu,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Ew,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Dw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(a){return $CLJS.n($CLJS.A(Mu.h(a)))?function(b,c){return Gw(Mu.h(b),b,c)}:$CLJS.n(function(){var b=pw.h(a);return $CLJS.n(b)?
ow.h(a):b}())?function(b,c){return Mw(hda,c)}:$CLJS.n(pw.h(a))?function(b,c){return Mw(ida,c)}:$CLJS.n(ow.h(a))?function(b,c){b=jw(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))Aw.l($CLJS.H(["zeroth"]));else{var d=Cw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(Kw)){var e=$CLJS.hf.g(Jw,$CLJS.Au(1,d));e=Lw(e,1);var f=$CLJS.id(d);d=$CLJS.Vd(f,100);f=vu(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(Hw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(iy,f);else{t=$CLJS.Vd(f,10);
var u=vu(f,10);t=0<t&&!(0<u)?$CLJS.F(gda,t):[$CLJS.p.h(0<t?$CLJS.F(Iw,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(iy,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Aw.l($CLJS.H([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else Gw(10,new $CLJS.h(null,5,[xw,0,zw," ",Ew,",",Dw,3,ow,!0],null),bw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=vu(c,100),c=11<e||19>e,e=vu(e,10),Aw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=jw(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))Aw.l($CLJS.H(["zero"]));else{var d=Cw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(Kw)?(d=$CLJS.hf.g(Jw,d),d=Lw(d,0),Aw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):Gw(10,new $CLJS.h(null,5,[xw,0,zw," ",Ew,",",Dw,3,ow,!0],null),bw(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[sx,"P",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,
null,tx,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){b=$CLJS.n(ow.h(a))?lw(b,-1):b;a=$CLJS.n(pw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=jw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);Aw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.A(a):$CLJS.hd(a)]));return c}}],null),new $CLJS.h(null,5,[sx,"C",Rw,new $CLJS.h(null,1,[Nw,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,
tx,null],null),null),wx,$CLJS.N,ux,function(a){return $CLJS.n(ow.h(a))?Nba:$CLJS.n(pw.h(a))?Oba:Pba}],null),new $CLJS.h(null,5,[sx,"F",Rw,new $CLJS.h(null,5,[Yw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.$w,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),ax,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],null),null),wx,$CLJS.N,ux,
function(){return bx}],null),new $CLJS.h(null,5,[sx,"E",Rw,new $CLJS.h(null,7,[Yw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),cx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.$w,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ax,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],
null),null),wx,$CLJS.N,ux,function(){return ex}],null),new $CLJS.h(null,5,[sx,"G",Rw,new $CLJS.h(null,7,[Yw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Zw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),cx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.$w,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ax,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),dx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,
1,[pw,null],null),null),wx,$CLJS.N,ux,function(){return Rba}],null),new $CLJS.h(null,5,[sx,"$",Rw,new $CLJS.h(null,4,[Zw,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Yw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return Sba}],null),new $CLJS.h(null,5,[sx,"%",Rw,new $CLJS.h(null,1,[$CLJS.Wx,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),rx,$CLJS.bh,wx,$CLJS.N,ux,function(){return function(a,b){a=$CLJS.Wx.h(a);for(var c=0;;)if(c<a)Du(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[sx,"\x26",Rw,new $CLJS.h(null,1,[$CLJS.Wx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,1,[cy,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){a=$CLJS.Wx.h(a);0<a&&((null!=$CLJS.Cu?$CLJS.Cu.C&32768||$CLJS.Bc===$CLJS.Cu.ag||($CLJS.Cu.C?
0:$CLJS.$a($CLJS.qu,$CLJS.Cu)):$CLJS.$a($CLJS.qu,$CLJS.Cu))?$CLJS.E.g(0,$CLJS.Ju(Mu.h($CLJS.q($CLJS.q($CLJS.Cu))),$CLJS.Lu))||Du():Du());--a;for(var c=0;;)if(c<a)Du(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[sx,"|",Rw,new $CLJS.h(null,1,[$CLJS.Wx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),rx,$CLJS.bh,wx,$CLJS.N,ux,function(){return function(a,b){a=$CLJS.Wx.h(a);for(var c=0;;)if(c<a)Aw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[sx,"~",Rw,new $CLJS.h(null,
1,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),rx,$CLJS.bh,wx,$CLJS.N,ux,function(){return function(a,b){a=$CLJS.qk.h(a);Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[sx,"\n",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,2,[ow,null,pw,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){$CLJS.n(pw.h(a))&&Du();return b}}],null),new $CLJS.h(null,5,[sx,"T",Rw,new $CLJS.h(null,2,[Tx,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
yw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,2,[pw,null,cy,null],null),null),wx,$CLJS.N,ux,function(a){return $CLJS.n(pw.h(a))?function(b,c){var d=Tx.h(b);b=yw.h(b);var e=d+$CLJS.Ju(Mu.h($CLJS.q($CLJS.q($CLJS.Cu))),$CLJS.Lu);e=0<b?vu(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}:function(b,c){var d=Tx.h(b);b=yw.h(b);var e=$CLJS.Ju(Mu.h($CLJS.q($CLJS.q($CLJS.Cu))),$CLJS.Lu);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
vu(e-d,b);Aw.l($CLJS.H([$CLJS.Se.g($CLJS.p,$CLJS.df(d," "))]));return c}}],null),new $CLJS.h(null,5,[sx,"*",Rw,new $CLJS.h(null,1,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,2,[ow,null,pw,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){var c=$CLJS.qk.h(a);return $CLJS.n(pw.h(a))?mw(b,c):lw(b,$CLJS.n(ow.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[sx,"?",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,1,[pw,null],null),null),wx,
$CLJS.N,ux,function(a){return $CLJS.n(pw.h(a))?function(b,c){var d=kw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return Uw(c,d,Sw.h(b))}:function(b,c){var d=kw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=jw(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=bw(d);Uw(c,d,Sw.h(b));return e}}],null),new $CLJS.h(null,5,[sx,"(",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,new $CLJS.h(null,3,[$CLJS.zx,")",Dx,null,$CLJS.Lt,null],null),ux,function(a){var b=$CLJS.n(function(){var c=
pw.h(a);return $CLJS.n(c)?ow.h(a):c}())?cca:$CLJS.n(ow.h(a))?eca:$CLJS.n(pw.h(a))?fca:bca;return function(c,d){a:{var e=$CLJS.A($CLJS.gx.h(c)),f=$CLJS.Cu;$CLJS.Cu=b.h?b.h($CLJS.Cu):b.call(null,$CLJS.Cu);try{var k=Uw(e,d,Sw.h(c));break a}finally{$CLJS.Cu=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[sx,")",Rw,$CLJS.N,rx,$CLJS.bh,wx,$CLJS.N,ux,function(){return null}],null),new $CLJS.h(null,5,[sx,"[",Rw,new $CLJS.h(null,1,[fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),rx,new $CLJS.ah(null,
new $CLJS.h(null,2,[ow,null,pw,null],null),null),wx,new $CLJS.h(null,3,[$CLJS.zx,"]",Dx,!0,$CLJS.Lt,$CLJS.$x],null),ux,function(a){return $CLJS.n(ow.h(a))?Uba:$CLJS.n(pw.h(a))?Vba:Tba}],null),new $CLJS.h(null,5,[sx,";",Rw,new $CLJS.h(null,2,[lx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),mx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,1,[ow,null],null),null),wx,new $CLJS.h(null,1,[$CLJS.Nt,!0],null),ux,function(){return null}],null),new $CLJS.h(null,
5,[sx,"]",Rw,$CLJS.N,rx,$CLJS.bh,wx,$CLJS.N,ux,function(){return null}],null),new $CLJS.h(null,5,[sx,"{",Rw,new $CLJS.h(null,1,[hx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,new $CLJS.h(null,2,[$CLJS.zx,"}",Dx,!1],null),ux,function(a){var b=pw.h(a);b=$CLJS.n(b)?ow.h(a):b;return $CLJS.n(b)?Zba:$CLJS.n(ow.h(a))?Xba:$CLJS.n(pw.h(a))?Yba:Wba}],null),new $CLJS.h(null,5,[sx,"}",Rw,$CLJS.N,rx,new $CLJS.ah(null,
new $CLJS.h(null,1,[ow,null],null),null),wx,$CLJS.N,ux,function(){return null}],null),new $CLJS.h(null,5,[sx,"\x3c",Rw,new $CLJS.h(null,4,[xw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),yw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ww,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,4,[ow,null,pw,null,tx,null,cy,null],null),null),wx,new $CLJS.h(null,3,[$CLJS.zx,"\x3e",Dx,!0,$CLJS.Lt,Cx],null),ux,function(){return aca}],
null),new $CLJS.h(null,5,[sx,"\x3e",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,1,[ow,null],null),null),wx,$CLJS.N,ux,function(){return null}],null),new $CLJS.h(null,5,[sx,"^",Rw,new $CLJS.h(null,3,[Xx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Zx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Yx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,1,[ow,null],null),null),wx,$CLJS.N,ux,function(){return function(a,b){var c=Xx.h(a),d=Zx.h(a),e=
Yx.h(a),f=$CLJS.n(ow.h(a))?Pw:Ow;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(ow.h(a))?$CLJS.td($CLJS.Oi.h(Sw.h(a))):$CLJS.td($CLJS.Oi.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[sx,"W",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,4,[ow,null,pw,null,
tx,null,cy,null],null),null),wx,$CLJS.N,ux,function(a){if($CLJS.n(function(){var c=pw.h(a);return $CLJS.n(c)?c:ow.h(a)}())){var b=$CLJS.gf.g($CLJS.n(pw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ot,null,$CLJS.Ux,null],null):$CLJS.Cf,$CLJS.n(ow.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[cy,!0],null):$CLJS.Cf);return function(c,d){d=jw(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Se.j(hy,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Ow,d],null):d}}return function(c,d){d=jw(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.Ov(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Ow,d],null):d}}],null),new $CLJS.h(null,5,[sx,"_",Rw,$CLJS.N,rx,new $CLJS.ah(null,new $CLJS.h(null,3,[ow,null,pw,null,tx,null],null),null),wx,$CLJS.N,ux,function(){return hca}],null),new $CLJS.h(null,5,[sx,"I",Rw,new $CLJS.h(null,1,[$CLJS.qk,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),rx,new $CLJS.ah(null,new $CLJS.h(null,1,[ow,null],null),null),wx,$CLJS.N,ux,function(){return gca}],null)]),ica=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
oca=new $CLJS.ah(null,new $CLJS.h(null,2,[rw,null,qw,null],null),null),lca=new $CLJS.h(null,2,[":",ow,"@",pw],null),cw=function cw(a){for(;;){if($CLJS.td(a))return!1;var c=cy.h(rx.h(xx.h($CLJS.A(a))));$CLJS.n(c)||(c=$CLJS.Re(cw,$CLJS.A($CLJS.gx.h(Rw.h($CLJS.A(a))))),c=$CLJS.n(c)?c:$CLJS.Re(cw,$CLJS.A($CLJS.Lt.h(Rw.h($CLJS.A(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},Nx=$CLJS.Ph(aw),Ex=new $CLJS.h(null,6,[$CLJS.Pj,"'",$CLJS.Tj,"#'",$CLJS.oba,"@",$CLJS.pba,"~",Qca,"@",Oca,"~"],null);
(function(){var a=Nx("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=bw(d);return dw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var jy=function(){var a=Nx("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=bw(d);return dw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ky=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),jda=function(){var a=Nx("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=bw(d);return dw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ly,kda=$CLJS.Xe($CLJS.N),lda=$CLJS.Xe($CLJS.N),mda=$CLJS.Xe($CLJS.N),nda=$CLJS.Xe($CLJS.N),oda=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
ly=new $CLJS.di($CLJS.Fh.g("cljs.pprint","simple-dispatch"),Lx,oda,kda,lda,mda,nda);wu(ly,$CLJS.Bj,function(a){if($CLJS.Va(Fx(a)))if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var b=Qv,c=Lv;Qv+=1;Lv=0;try{Fv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Ov($CLJS.A(e)),$CLJS.B(e))){$CLJS.ac($CLJS.Cu," ");Wv(Vv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.ac($CLJS.Cu,"...");break}Jv()}finally{Lv=c,Qv=b}}return null});wu(ly,$CLJS.Jj,Gx);wu(ly,$CLJS.hk,Hx);
wu(ly,$CLJS.Zi,jy);wu(ly,null,function(){return $CLJS.ac($CLJS.Cu,$CLJS.Mh.l($CLJS.H([null])))});wu(ly,$CLJS.ci,Ix);Nv=ly;
var my=function(){var a=Nx("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=bw(d);return dw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),ny=function(){var a=Nx("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=bw(d);return dw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),oy=$CLJS.N,pda=function(a){return $CLJS.eg.g($CLJS.N,$CLJS.jf($CLJS.Td,$CLJS.H([function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(t))),$CLJS.hd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.h($CLJS.gh($CLJS.A(l))),$CLJS.hd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.eg.g($CLJS.N,$CLJS.hf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.ie(c);e=$CLJS.n(e)?e:$CLJS.Hd(new $CLJS.ah(null,new $CLJS.h(null,24,[$CLJS.$i,"null",$CLJS.pj,"null",$CLJS.rk,"null",$CLJS.tk,"null",$CLJS.kj,"null",$CLJS.Lj,"null",$CLJS.Hj,"null",$CLJS.Mj,"null",$CLJS.qi,"null",$CLJS.Wj,"null",$CLJS.Aj,"null",$CLJS.sj,"null",$CLJS.dk,"null",$CLJS.sk,"null",$CLJS.Ri,"null",$CLJS.Mi,"null",$CLJS.ij,"null",$CLJS.uj,"null",$CLJS.aj,"null",$CLJS.Tj,"null",$CLJS.Pj,"null",$CLJS.kk,"null",$CLJS.Ii,"null",$CLJS.gk,"null"],null),null),c);return $CLJS.Va(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fh.g("clojure.core",$CLJS.gh(c)),d],null):b},a))}($CLJS.Ig([$CLJS.aj,$CLJS.Mi,Gca,$CLJS.Wj,Lca,Dca,Fca,Mca,Jca,Ica,Kca,Yca,Sca,$CLJS.gk,Rca,Wca,Uca,dda,Bca,$CLJS.sj,Zca,$ca,cda,$CLJS.Qj,Pca,eda,Cca,Eca,Hca,ada],[my,function(a){var b=$CLJS.hd(a),c=$CLJS.A($CLJS.Lc($CLJS.Lc(a)));if($CLJS.zd(b)){a=oy;oy=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.Ee([$CLJS.A(b),"%"]):$CLJS.eg.g($CLJS.N,$CLJS.hf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.yu(1,$CLJS.D(b)+1)));try{return function(){var d=Nx("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=bw(k);return dw(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{oy=a}}else return Ox(a)},Qx,ny,function(a){if(3<$CLJS.D(a)){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,
"#");else{var b=Qv,c=Lv;Qv+=1;Lv=0;try{Fv("(",")");$v(Xv,1);$CLJS.Se.g(function(){var l=Nx("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=bw(u);return dw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.bf(3,a));;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,
"#");else{a=Qv;var f=Lv;Qv+=1;Lv=0;try{Fv(null,null),$CLJS.Ov($CLJS.A(e)),$CLJS.B(e)&&($CLJS.ac($CLJS.Cu," "),Wv(Tv),$CLJS.Ov($CLJS.hd(e))),Jv()}finally{Lv=f,Qv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Cu," ");Wv(Vv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Cu,"...");break}Jv()}finally{Lv=c,Qv=b}}return null}return Ox(a)},my,Px,Px,Qx,my,Qx,ny,ny,my,ny,Qx,Qx,my,Qx,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.xd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{d=Qv;var k=Lv;Qv+=1;Lv=0;try{Fv("(",")");(function(){var m=Nx("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=bw(v);return dw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=Nx("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,
x)}function u(v){v=bw(v);return dw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&ew(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=Nx("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=bw(v);return dw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){xca($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,Wv(Vv),a=b;else break}Jv()}finally{Lv=k,Qv=d}}return null}return $CLJS.Ov(a)},Qx,function(a){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{var b=Qv,c=Lv;Qv+=1;Lv=0;try{Fv("(",")");$v(Xv,1);$CLJS.Ov($CLJS.A(a));if($CLJS.B(a)){$CLJS.ac($CLJS.Cu," ");Wv(Vv);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{a=Qv;var f=Lv;Qv+=1;Lv=0;try{Fv(null,null),$CLJS.Ov($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.ac($CLJS.Cu," "),Wv(Tv),$CLJS.Ov($CLJS.hd(e))),Jv()}finally{Lv=f,Qv=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.ac($CLJS.Cu," ");Wv(Vv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.ac($CLJS.Cu,"...");break}}Jv()}finally{Lv=c,Qv=b}}return null},Qx,Px,Px,my,my,Qx,Qx,my]))),py,qda=$CLJS.Xe($CLJS.N),rda=$CLJS.Xe($CLJS.N),sda=$CLJS.Xe($CLJS.N),tda=$CLJS.Xe($CLJS.N),uda=$CLJS.J.j($CLJS.N,$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
py=new $CLJS.di($CLJS.Fh.g("cljs.pprint","code-dispatch"),Lx,uda,qda,rda,sda,tda);wu(py,$CLJS.Bj,function(a){if($CLJS.Va(Fx(a))){var b=pda.call(null,$CLJS.A(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):Ox(a)}return null});wu(py,$CLJS.Gi,function(a){var b=a.h?a.h(oy):a.call(null,oy);return $CLJS.n(b)?Aw.l($CLJS.H([b])):$CLJS.n(gy)?Aw.l($CLJS.H([$CLJS.gh(a)])):Bu.call(null,a)});wu(py,$CLJS.Jj,Gx);wu(py,$CLJS.hk,Hx);wu(py,$CLJS.Zi,jy);wu(py,Jx,jda);
wu(py,Kx,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.ab(a).name;var e=$CLJS.oh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?ky.h?ky.h(e):ky.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.ya(a)),": "].join("");if($CLJS.n(Rv()))$CLJS.ac($CLJS.Cu,"#");else{c=Qv;d=Lv;Qv+=1;Lv=0;try{Fv(b,"\x3e");$v(Xv,-(b.length-2));Wv(Vv);var f=null!=a?a.I&1||$CLJS.Bc===a.jj?!0:a.I?!1:$CLJS.$a(su,a):$CLJS.$a(su,a);var k=f?!tu(a):f;$CLJS.Ov(k?Tca:$CLJS.q(a));Jv()}finally{Lv=d,Qv=c}}return null});wu(py,null,Bu);
wu(py,$CLJS.ci,Ix);Nv=ly;