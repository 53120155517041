var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var gna,U_,hna,W_,X_,Y_,Z_,s0,kna,jna,lna,ina,y0,k0,A0,mna,m0;gna=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};U_=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Hd($CLJS.bea,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
hna=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.yB,!0],null)),c=$CLJS.J.g(b,$CLJS.aea),d=$CLJS.J.g(b,$CLJS.yB);return $CLJS.y(a)?(b=$CLJS.DV(function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=U_(x,$CLJS.Va(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(U_(u,
$CLJS.Va(d)),k($CLJS.Lc(l)))}return null}},null,null)}(gna($CLJS.cB(a)))}()),$CLJS.n(c)?$CLJS.DV($CLJS.af(c,b)):b):null};$CLJS.V_=function(a){return $CLJS.lP.h($CLJS.A($CLJS.vL.h(a)))};W_=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=W_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=W_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.table",a);}return a};
X_=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=X_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=X_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.card",a);}return a};Y_=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=Y_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Y_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.metric",a);}return a};
Z_=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=Z_[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Z_._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.fields",a);}return a};$CLJS.$_=function(a){return $CLJS.DZ(a)?a:$CLJS.qE.h(a)};$CLJS.a0=function(a,b){return W_($CLJS.$_(a),b)};$CLJS.b0=function(a,b){return Z_($CLJS.$_(a),b)};$CLJS.c0=function(a,b){return X_($CLJS.$_(a),b)};$CLJS.d0=function(a,b){return Y_($CLJS.$_(a),b)};
$CLJS.e0=function(a,b,c){if($CLJS.Xa(b)||$CLJS.xd(b)){$CLJS.n(b.__mbcache)||(b.__mbcache=$CLJS.Xe($CLJS.N));var d=b.__mbcache;if($CLJS.n(d)){var e=$CLJS.J.g($CLJS.q(d),a);if($CLJS.n(e))return e;b=c.h?c.h(b):c.call(null,b);$CLJS.Oh.v(d,$CLJS.R,a,b);return b}}return c.h?c.h(b):c.call(null,b)};
s0=function(a,b,c){var d=$CLJS.f0.j(a,b,c);return $CLJS.fl.l($CLJS.H([$CLJS.dm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.uE,$CLJS.Uj],null)),function(){var e=$CLJS.g0.v(a,b,c,$CLJS.h0);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.i0,e],null):null}(),function(){var e=$CLJS.EA($CLJS.Ei,$CLJS.wB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null}(),function(){var e=$CLJS.gL.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.VW,$CLJS.j0.j(a,b,$CLJS.Fd(e)?$CLJS.a0(a,e):"string"===typeof e?
$CLJS.c0(a,$CLJS.NW(e)):null)],null):null}(),function(){var e=$CLJS.UK.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[k0,$CLJS.E.g(e,$CLJS.FZ),$CLJS.l0,$CLJS.E.g(e,$CLJS.OZ),m0,$CLJS.E.g(e,$CLJS.LZ),$CLJS.n0,$CLJS.E.g(e,$CLJS.NZ),ina,$CLJS.E.g(e,$CLJS.oL),jna,$CLJS.E.g(e,$CLJS.iL)],null):null}(),function(){var e=$CLJS.SZ.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.o0,e],null)}(),$CLJS.dm(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.p0,$CLJS.q0,$CLJS.r0],null))]))};
$CLJS.t0=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.r0=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.u0=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.n0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.p0=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.v0=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);
$CLJS.o0=new $CLJS.M(null,"selected","selected",574897764);kna=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);jna=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);lna=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.w0=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);
$CLJS.x0=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);ina=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);y0=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.z0=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);k0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);
A0=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);$CLJS.B0=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.l0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.C0=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.q0=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);mna=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.i0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.D0=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.h0=new $CLJS.M(null,"long","long",-171452093);$CLJS.E0=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.F0=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);m0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.G0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();
$CLJS.H0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();
$CLJS.g0=function(){function a(f,k,l,m){var t=$CLJS.uE.h($CLJS.NE(l));if($CLJS.n(t))return t;try{return $CLJS.G0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.gi($CLJS.pE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([l])),$CLJS.zV(k)])),new $CLJS.h(null,2,[$CLJS.MF,f,$CLJS.HE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.ci):e.call(null,f,k,l,$CLJS.ci)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.I0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.NE(f));if($CLJS.n(k))return k;try{return $CLJS.H0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.gi($CLJS.pE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f])),$CLJS.zV(k)])),new $CLJS.h(null,3,[$CLJS.HE,f,$CLJS.MF,d,$CLJS.KL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.G0.m(null,$CLJS.ci,function(a,b,c){a=$CLJS.OA($CLJS.Jy);$CLJS.n($CLJS.NA("metabase.lib.metadata.calculation",a))&&$CLJS.MA("metabase.lib.metadata.calculation",a,$CLJS.BW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c])),lna,$CLJS.iC(c)])),null);return $CLJS.zd(c)&&$CLJS.A(c)instanceof $CLJS.M?$CLJS.gh($CLJS.A(c)):$CLJS.Mh.l($CLJS.H([c]))});
$CLJS.H0.m(null,$CLJS.ci,function(a,b,c){return hna($CLJS.IA($CLJS.g0.j(a,b,c),/[\(\)]/,""))});$CLJS.J0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.nna=function(){function a(d,e,f){return $CLJS.J0.j(d,e,$CLJS.zh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.K0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();
$CLJS.L0=function(){function a(d,e,f){var k=$CLJS.NE(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.vG),m=$CLJS.Ei.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Hd($CLJS.wH,l):l)?$CLJS.vj:null;if($CLJS.n(l))return l;k=$CLJS.wB.h(k);if($CLJS.n(k))return k;d=$CLJS.K0.j(d,e,f);return $CLJS.wC(d,$CLJS.zj)?d:$CLJS.zj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.K0.m(null,$CLJS.ci,function(a,b,c){return $CLJS.UE(c)});$CLJS.K0.m(null,$CLJS.jF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.L0.j(a,b,c)});$CLJS.K0.m(null,$CLJS.aJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.UE(c):$CLJS.L0.j(a,b,d)});
$CLJS.M0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();
$CLJS.M0.m(null,$CLJS.ci,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Dj,$CLJS.SK,$CLJS.wB,$CLJS.L0.j(a,b,c),$CLJS.T,$CLJS.I0.j(a,b,c),$CLJS.uE,$CLJS.g0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.gi($CLJS.pE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([$CLJS.iC(c)])),$CLJS.zV(d)])),new $CLJS.h(null,3,[$CLJS.MF,a,$CLJS.KL,b,$CLJS.HE,c],null),d);}throw e;}});
$CLJS.f0=function(){function a(e,f,k){return $CLJS.M0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.N0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();
$CLJS.X(y0,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.hk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uE,$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.i0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VW,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,y0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[k0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.l0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[m0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[A0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[mna,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[kna,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vs,$CLJS.Ms],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Yj],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qt,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.E0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Yj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.C0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.o0,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Rs,!0],null),$CLJS.Ms],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.u0,
new $CLJS.h(null,1,[$CLJS.Rs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.mG,$CLJS.fG],null)],null)],null));
$CLJS.j0=function(){function a(d,e,f){return $CLJS.e0($CLJS.zh.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.N0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.gi($CLJS.pE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.iC(k),$CLJS.zV(l)])),new $CLJS.h(null,3,[$CLJS.MF,d,$CLJS.KL,e,$CLJS.HE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.N0.m(null,$CLJS.ci,function(a,b,c){return s0(a,b,c)});$CLJS.N0.m(null,$CLJS.ZK,function(a,b,c){return $CLJS.fl.l($CLJS.H([s0(a,b,c),new $CLJS.h(null,1,[A0,$CLJS.E.g($CLJS.V_(a),$CLJS.Yi.h(c))],null)]))});
$CLJS.O0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();$CLJS.O0.m(null,$CLJS.zB,function(){return $CLJS.Cf});
$CLJS.P0=function(){function a(f,k,l,m){m=$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.B0,$CLJS.PW()],null),m]));return $CLJS.O0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.MW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.Q0=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.gj,$CLJS.kC],null),$CLJS.gj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.iC(l)},e,a,b,c,d)}();$CLJS.Q0.m(null,$CLJS.zB,function(){return $CLJS.Cf});
$CLJS.Q0.m(null,$CLJS.ci,function(a,b,c,d){return $CLJS.O0.v(a,b,c,d)});
$CLJS.R0=function(){function a(f,k,l,m){m=$CLJS.fl.l($CLJS.H([$CLJS.fl.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.B0,$CLJS.PW()],null),new $CLJS.h(null,4,[$CLJS.z0,!0,$CLJS.F0,!0,$CLJS.v0,!0,$CLJS.D0,!0],null)])),m]));return $CLJS.Q0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.MW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();module.exports={display_name:$CLJS.g0};