import React from "react";
import MetabaseSettings from "metabase/lib/settings";
import AuthScene from "metabase/auth/components/AuthScene";
import { oktaRedirect } from "metabase/lib/auth";
import BackToLoginOkta from "./BackToLoginOkta";

export default class OktaRedirect extends React.Component {
  constructor(props, context) {
    super(props, context);
    if (MetabaseSettings.isOktaEnabled()) {
      const original_url = window.location.href;
      oktaRedirect(original_url);
    } else {
      window.location.href = MetabaseSettings.get("site-url") + "/auth/login";
    }
  }

  render() {
    return (
      <div className="full-height bg-white flex flex-column flex-full md-layout-centered">
        <div className="wrapper">
          <div className="Login-wrapper Grid--full">
            <div className="Grid-cell flex layout-centered text-brand">
              <BackToLoginOkta />
            </div>
          </div>
        </div>
        <AuthScene />
      </div>
    );
  }
}
