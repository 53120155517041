import React, { Component } from "react";
import { t } from "ttag";

import { connect } from "react-redux";
import { updateOktaSettings } from "metabase/admin/settings/settings";

import SettingsBatchForm from "./SettingsBatchForm";

class SettingsOktaForm extends Component {
  render() {
    return (
      <SettingsBatchForm
        {...this.props}
        breadcrumbs={[
          [t`Authentication`, "/admin/settings/authentication"],
          [t`OKTA`],
        ]}
        enabledKey="okta-enabled"
        layout={[
          {
            title: t`Okta Web App Settings`,
            settings: [
              "okta-enabled",
              "okta-domain",
              "okta-client-id",
              "okta-client-secret",
            ],
          },
        ]}
      />
    );
  }
}

export default connect(null, { updateSettings: updateOktaSettings })(SettingsOktaForm);