import { t } from "ttag";
import { connect } from "react-redux";
import { getSetting } from "metabase/selectors/settings";
import { updateSettings } from "metabase/admin/settings/settings";
import type { Dispatch, State } from "metabase-types/store";
import AuthCard from "../../components/AuthCard";
import { OKTA_SCHEMA } from "../../constants";

const mapStateToProps = (state: State) => ({
  type: "okta",
  name: t`Sign in with Okta`,
  description: t`Allows users with existing Metabase accounts to login with a Okta account that matches their email address in addition to their Okta username and password.`,
  isConfigured: getSetting(state, "okta-configured?"),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDeactivate: () => dispatch(updateSettings(OKTA_SCHEMA.getDefault())),
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default connect(mapStateToProps, mapDispatchToProps)(AuthCard);
